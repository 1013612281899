import {Component, Inject} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BestTime, Category, Classification, Gender, TeamMember} from '../../models/TeamMember';
import 'tinymce/tinymce.min.js';
import { TinyMceSettingsService } from '../../utils/services/TinyMceSettingsService';
import { NgForm } from '@angular/forms';
declare var tinymce: any;

const HttpUploadOptions = {
  headers: new HttpHeaders({"Content-Type": "multipart/form-data"})
};

@Component({
  selector: 'app-manage-best-time',
  templateUrl: './manage-best-time.component.html',
  styleUrls: ['./manage-best-time.component.css']
})
export class ManageBestTimeComponent {

  public teamMember: TeamMember;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, public activeModal: NgbActiveModal) {

  }

  addBestResult() : void {
    this.teamMember.bestResults.push(new BestTime());
  }

  deleteResult(result: BestTime) {
    this.teamMember.bestResults.splice(this.teamMember.bestResults.indexOf(result), 1);
  }

  trackByIndex(index: number) {
    return index;
  }

  setTeamMember(member: TeamMember): void {
    this.teamMember = member;
  }

  save(form: NgForm): void {
    if (form.valid) {
        this.httpClient.put(this.baseUrl + 'api/team/' + this.teamMember.id + "/besttimes", this.teamMember.bestResults).subscribe(x => {
          this.activeModal.close(this.teamMember);
        });
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgbCollapseModule, NgbModule, NgbDateAdapter, NgbDateNativeAdapter} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {CoachesComponent} from './coaches/coaches.component';
import {CalendarComponent} from './calendar/calendar.component';
import {PhotoAlbumOverviewComponent} from './photo-album/photo-album-overview.component';
import {ContactComponent} from './contact/contact.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CalendarModule, DateAdapter, CalendarDateFormatter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {CommonModule, DatePipe} from '@angular/common';
import {FlatpickrModule} from 'angularx-flatpickr';
import {SignInComponent} from './admin/signin.component';
import { AuthHttpInterceptor } from './utils/interceptors/AuthHttpInterceptor';
import { CustomDateFormatter } from './utils/formatters/CustomDateFormatter';
import {AuthGuard} from './utils/guards/AuthGuard';
import {CookieService} from 'ngx-cookie-service';
import { AuthService } from './utils/services/AuthService';
import {GenderIconPipe} from './utils/pipes/GenderPipe';
import {ManageTeamMembersComponent} from './admin/teammembers/teammembers.component';
import {ManageTeamMemberComponent} from './admin/teammembers/manage-teammember.component';
import { EnumListPipe } from './utils/pipes/EnumListPipe';
import { NgxUploaderModule } from 'ngx-uploader';
import {ManageCalendarComponent} from './admin/calendar/calendar.component';
import {AddCalenderItemComponent} from './admin/calendar/add-calendar-item.component';
import {CalendarEventDetailComponent} from './calendar/calendar-event-detail.component';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { ReportsComponent } from './reports/reports.component';
import { ManageReportsComponent } from './admin/reports/manage-reports.component';
import { ManageReportComponent } from './admin/reports/manage-report.component';
import { ManageCoachComponent } from './admin/coaches/manage-coach.component';
import {ManageCoachesComponent} from './admin/coaches/coaches.component';
import { CoupleTeamMemberComponent } from './admin/teammembers/couple-teammember.component';
import { TeamMemberDetailComponent } from './team-members/teammember-detail.component';
import { ManageUsersComponent } from './admin/users/users.component';
import { RoleAuthGuard } from './utils/guards/RoleAuthGuard';
import { AddUserComponent } from './admin/users/add-user.component';
import {EditorModule} from "@tinymce/tinymce-angular";
import {ManagePhotoalbumComponent} from './admin/photoalbums/manage-photoalbum.component';
import {ManagePhotoalbumsComponent} from './admin/photoalbums/manage-photoalbums.component';
import {UploadPicturesComponent} from './admin/photoalbums/upload-pictures.component';
import {PhotoAlbumComponent} from './photo-album/photo-album.component';
import { LightboxModule } from 'ngx-lightbox';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularDateHttpInterceptor } from './utils/interceptors/AngularDateHttInterceptor';
import { ProfileComponent } from './profile/profile.component';
import { DateAfterDateValidatorDirective } from './utils/validators/DateAfterDateValidator';
import {ErrorComponent} from "./other/error.component";
import {TeamMemberOverviewComponent } from "./team-members/teammember-overview.component";
import {CoachDetailComponent} from "./coaches/coach-detail.component";
import {Roles} from "./models/Roles";
import {ManageBestTimeComponent} from "./admin/teammembers/manage-best-time.component ";
import { TimeSpanValidatorDirective } from './utils/validators/TimeSpanValidator';
import {HomeComponent} from "./home/home.component";
import {OtherComponent} from "./useful/other.component";
import {PartnersComponent} from "./useful/partners.component";
import {SponsorsComponent} from "./useful/sponsors.component";
import {PerformanceFeesComponent} from "./info/fees.component";
import {InternalRulesComponent} from "./info/rules.component";
import {ManageSponsorsComponent} from "./admin/useful/manage-sponsors.component";
import {ManageSponsorComponent} from "./admin/useful/manage-sponsor.component";
import {NgxCaptchaModule} from "ngx-captcha";
import {ManageOtherComponent} from "./admin/useful/manage-other.component";
import {ManageInfoComponent} from "./admin/useful/manage-info.component";
import {FileSaverModule} from "ngx-filesaver";
import {ChangePasswordComponent} from "./profile/change-password.component";
import {SponsorBannerComponent} from "./useful/sponsor-banner.component";
import { ManagePartnersComponent } from './admin/useful/manage-partners.component';
import { ManagePartnerComponent } from './admin/useful/manage-partner.component';
import {RecreaComponent} from "./recrea/recrea.component";
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
     //return new TranslateHttpLoader(http);
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?'+ Math.random());
}

registerLocaleData(localeNl);

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        TeamMemberOverviewComponent,
        CoachesComponent,
        CalendarComponent,
        ContactComponent,
        PhotoAlbumOverviewComponent,
        SignInComponent,
        GenderIconPipe,
        EnumListPipe,
        ManageTeamMembersComponent,
        ManageTeamMemberComponent,
        ManageCalendarComponent,
        AddCalenderItemComponent,
        CalendarEventDetailComponent,
        ReportsComponent,
        ManageReportsComponent,
        ManageReportComponent,
        ManageCoachComponent,
        ManageCoachesComponent,
        CoupleTeamMemberComponent,
        TeamMemberDetailComponent,
        ManageUsersComponent,
        AddUserComponent,
        ManagePhotoalbumComponent,
        ManagePhotoalbumsComponent,
        UploadPicturesComponent,
        PhotoAlbumComponent,
        ProfileComponent,
        DateAfterDateValidatorDirective,
        TimeSpanValidatorDirective,
        ErrorComponent,
        CoachDetailComponent,
        ManageBestTimeComponent,
        HomeComponent,
        SponsorsComponent,
        PartnersComponent,
        OtherComponent,
        InternalRulesComponent,
        PerformanceFeesComponent,
        ManageSponsorsComponent,
        ManageSponsorComponent,
        ManagePartnersComponent,
        ManagePartnerComponent,
        ManageOtherComponent,
        ManageInfoComponent,
        ChangePasswordComponent,
        SponsorBannerComponent,
        RecreaComponent
    ],
    imports: [
        NgxSpinnerModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        EditorModule,
        NgxCaptchaModule,
        BrowserAnimationsModule,
        NgxUploaderModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        LightboxModule,
        CommonModule,
        FlatpickrModule.forRoot(),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgbCollapseModule,
        FileSaverModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot([
            { path: '', component: HomeComponent, pathMatch: 'full', data: { title: "Home" } },
            { path: 'signin', component: SignInComponent, data: { title: "SignIn" } },
            { path: 'admin/calendar', component: ManageCalendarComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.CalendarManager, title: "ManageCalendar" } },
            { path: 'admin/teammembers', component: ManageTeamMembersComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.Admin, title: "ManageTeamMembers" } },
            { path: 'admin/photo-albums', component: ManagePhotoalbumsComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.PhotoAlbumManager, title: "ManagePictures" } },
            { path: 'admin/coaches', component: ManageCoachesComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.Admin, title: "ManageCoaches" } },
            { path: 'admin/reports', component: ManageReportsComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.NewsitemsManager, title: "ManageReports" } },
            { path: 'admin/sponsors', component: ManageSponsorsComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.SponsorManager, title: "ManageSponsors" } },
            { path: 'admin/partners', component: ManagePartnersComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.PartnerManager, title: "ManagePartners" } },
            { path: 'admin/other', component: ManageOtherComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.InfoManager, title: "ManageInfo" } },
            { path: 'admin/users', component: ManageUsersComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.Admin, title: "ManageUsers" } },
            { path: 'user/profile', component: ProfileComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.User, title: "Profile" } },
            { path: 'user/change-pass', component: ChangePasswordComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.User, title: "ChangePassword" } },
            { path: 'info/performancefees', component: PerformanceFeesComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.User, title: "PerformanceFees" } },
            { path: 'info/internalrules', component: InternalRulesComponent, canActivate: [AuthGuard, RoleAuthGuard], data: { expectedRole: Roles.User, title: "InternalRules" } },
            { path: 'team/elite', component: TeamMemberOverviewComponent, data: { isYoung: false, top: false, elite: true, title: "Team" } },
            { path: 'team/top', component: TeamMemberOverviewComponent, data: { isYoung: false, top: true, elite: false, title: "Team" } },
            { path: 'team/older', component: TeamMemberOverviewComponent, data: { isYoung: false, top: false, elite: false, title: "Team" } },
            { path: 'team/young', component: TeamMemberOverviewComponent, data: { isYoung: true, top: false, elite: false, title: "Team" } },
            { path: 'useful/sponsors', component: SponsorsComponent, data: { title: "Sponsors" } },
            { path: 'useful/partners', component: PartnersComponent, data: { title: "Partners" } },
            { path: 'useful/other', component: OtherComponent, data: { title: "Other" } },
            { path: 'coaches', component: CoachesComponent, data: { title: "Coaches" } },
            { path: 'calendar', component: CalendarComponent, data: { title: "Calendar" } },
            { path: 'contact', component: ContactComponent, data: { title: "Contact" } },
            { path: 'recrea', component: RecreaComponent, data: { title: "Recrea" } },
            { path: 'reports', component: ReportsComponent, data: { title: "Reports" } },
            { path: 'reports/:reportId', component: ReportsComponent, data: { title: "Reports" } },
            { path: 'photo-albums/overview', component: PhotoAlbumOverviewComponent, data: { title: "Pictures" } },
            { path: 'photo-albums/:albumId', component: PhotoAlbumComponent, data: { title: "Pictures" } },
        ], { relativeLinkResolution: 'legacy' })
    ],
    providers: [
        DatePipe, {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: AngularDateHttpInterceptor,
            multi: true
        },
        AngularDateHttpInterceptor,
        AuthHttpInterceptor,
        AuthGuard,
        RoleAuthGuard,
        AuthService,
        CookieService,
        {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter
        },
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }

}
